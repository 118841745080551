<template>
	<form action="#" @submit.prevent="submit" class="settings-notifications">
		<h2 class="section-title">Уведомления</h2>
		<fieldset v-if="settings.chat" class="settings-notifications__fieldset">
			<legend>Чаты</legend>
			<ul class="settings-notifications__list">
				<li class="settings-notifications__item">
					<label for="sms-message-notification" class="text">
						Получать уведомления о поступивших сообщениях по SMS
					</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="sms-message-notification"
							v-model="settings.chat.message.sms"
							type="checkbox"
						/>
						<label class="tgl-btn" for="sms-message-notification"></label>
					</div>
				</li>
				<li class="settings-notifications__item">
					<label for="email-message-notification" class="text"
						>Получать уведомления о поступивших сообщениях по E-mail</label
					>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="email-message-notification"
							v-model="settings.chat.message.email"
							type="checkbox"
						/>
						<label class="tgl-btn" for="email-message-notification"></label>
					</div>
				</li>
				<li class="settings-notifications__item">
					<label for="push-message-notification" class="text"
						>Получать push-уведомления о поступивших сообщениях
					</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="push-message-notification"
							v-model="settings.chat.message.push"
							type="checkbox"
						/>
						<label class="tgl-btn" for="push-message-notification"></label>
					</div>
				</li>
			</ul>
		</fieldset>
		<fieldset v-if="settings.place" class="settings-notifications__fieldset">
			<legend>Напоминания о бронировании</legend>
			<ul class="settings-notifications__list">
				<li class="settings-notifications__item">
					<label for="sms-trip-notification" class="text"
						>Получать напоминания о предстоящей поездке по SMS</label
					>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="sms-trip-notification"
							v-model="settings.place.booking.sms"
							type="checkbox"
						/>
						<label class="tgl-btn" for="sms-trip-notification"></label>
					</div>
				</li>
				<li class="settings-notifications__item">
					<label for="email-trip-notification" class="text"
						>Получать напоминания о предстоящей поездке по E-mail</label
					>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="email-trip-notification"
							v-model="settings.place.booking.email"
							type="checkbox"
						/>
						<label class="tgl-btn" for="email-trip-notification"></label>
					</div>
				</li>
				<li class="settings-notifications__item">
					<label for="push-trip-notification" class="text"
						>Получать push-уведомления о предстоящей поездке
					</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="push-trip-notification"
							v-model="settings.place.booking.push"
							type="checkbox"
						/>
						<label class="tgl-btn" for="push-trip-notification" />
					</div>
				</li>
			</ul>
		</fieldset>
		<fieldset v-if="settings.place" class="settings-notifications__fieldset">
			<legend>Новые статьи</legend>
			<ul class="settings-notifications__list">
				<li class="settings-notifications__item">
					<label for="email-recomendation-notification" class="text">
						Получать push-уведомления о новых статьях для вас
					</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="email-recomendation-notification"
							v-model="settings.place.recommendations.push"
							type="checkbox"
						/>
						<label class="tgl-btn" for="email-recomendation-notification" />
					</div>
				</li>
			</ul>
		</fieldset>
		<fieldset v-if="settings.account" class="settings-notifications__fieldset">
			<legend>Изменения в правилах пользования сервисом</legend>
			<ul class="settings-notifications__list">
				<li class="settings-notifications__item">
					<label for="email-chandes-notification" class="text"
						>Уведомлять об изменениях в правилах пользования на Email</label
					>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="email-chandes-notification"
							v-model="settings.account.rules.email"
							type="checkbox"
						/>
						<label class="tgl-btn" for="email-chandes-notification"></label>
					</div>
				</li>
			</ul>
		</fieldset>
		<fieldset v-if="settings.account" class="settings-notifications__fieldset">
			<legend>Обратная связь</legend>
			<ul class="settings-notifications__list">
				<li class="settings-notifications__item">
					<label for="sms-tech-notification" class="text">
						Получать ответы на вопросы от поддержки пользователей по SMS
					</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="sms-tech-notification"
							v-model="settings.account.support.sms"
							type="checkbox"
						/>
						<label class="tgl-btn" for="sms-tech-notification"></label>
					</div>
				</li>
				<li class="settings-notifications__item">
					<label for="email-tech-notification" class="text">
						Получать ответы на вопросы от поддержки пользователей по E-mail
					</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="email-tech-notification"
							v-model="settings.account.support.email"
							type="checkbox"
						/>
						<label class="tgl-btn" for="email-tech-notification"></label>
					</div>
				</li>
				<li class="settings-notifications__item">
					<label for="push-tech-notification" class="text">
						Получать ответы на вопросы от поддержи пользователей через
						push-уведомления
					</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="push-tech-notification"
							v-model="settings.account.support.push"
							type="checkbox"
						/>
						<label class="tgl-btn" for="push-tech-notification"></label>
					</div>
				</li>
			</ul>
		</fieldset>
		<button class="btn" type="submit" style="width: 100%">Сохранить</button>
	</form>
</template>

<script>
export default {
	data() {
		return {
			settings: {}
		}
	},
	async mounted() {
		const response = await this.$api.notification.get()

		if (response.status) {
			this.settings = response.response
		}
	},
	methods: {
		async submit() {
			const response = await this.$api.notification.update(this.settings)

			if (response.status) {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Данные найстроки уведомлений успешно обновлены'
				})

				return
			}

			console.error('Error update notification: ', response)
		}
	}
}
</script>


<style lang="sass" scoped>
.settings-notifications
	.section-title
		margin-top: 0
		margin-bottom: 30px

	&__fieldset
		legend
			font-weight: 600
</style>